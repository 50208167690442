<template>
  <Loading v-if="initLoading" />
  <div v-else>
    <Header @clickRight="clickRight" callBack @click="goBack">
      <template>{{ data.goods.name }}</template>
      <template v-slot:right v-if="$route.query.platform">
        <img class="fenxiang" src="../../../static/image/fenxiang.svg" alt="" />
      </template>
    </Header>
    <div class="head">
      <img :src="data.goods.showPic" alt="" />
      <div class="tag" v-if="data.Exclusive">
        <img src="../../assets/images/zhuanxiang.png" alt="" />
        {{ data.Exclusive }}
      </div>
    </div>
    <div class="content">
      <div class="title">{{ data.goods.name }}</div>
      <div class="pic">
        <div class="left"><span>¥</span>{{ data.currentPrice }}</div>
        <div class="middle">¥{{ data.originalPrice }}</div>
        <div class="right">{{ data.pointNumber || 0 }}交子可兑换</div>
      </div>
      <div class="tag">
        <div class="left">限量</div>
        <div class="right">{{ data.limitTotalNum || 0 }}份</div>
      </div>
      <div class="time">换购时间: {{ data.beginTime }}-{{ data.endTime }}</div>
      <div class="line"></div>
      <div class="science">兑换所需藏品材料：</div>
      <div class="info1">{{ data.keywords }}</div>
      <div class="material">
        <div style="white-space: nowrap">
          <div
            class="item"
            v-for="item in data.haveGoodList"
            :key="item.id"
            @click="goTo(item)"
          >
            <img :src="item.listPic" alt="" />
            <div class="mask" v-if="total(item.id) != item.number"></div>
            <div class="noSelect ico" v-if="!total(item.id)">
              x{{ item.number }}
            </div>
            <div class="ok ico" v-else-if="total(item.id) == item.number"></div>
            <div class="noOk ico" v-else>
              {{ total(item.id) - item.number }}
            </div>
          </div>
        </div>
      </div>
      <div class="info">
        集齐以上{{ data.haveGoodList.length || 0 }}款藏品可进行兑换,
        <span>此次兑换需消耗{{ data.pointNumber || 0 }}交子</span>
      </div>
      <div class="brand" v-if="data.brand">
        <div class="left">{{ data.brand }}</div>
        <div class="right">
          <img :src="data.logo" alt="" />
        </div>
      </div>
      <div class="key" v-if="data.haveQ != 0">
        <div class="left">
          <img :src="data.keyList.listPic" alt="" />
        </div>
        <div class="middle">
          <div class="title">{{ data.keyList.name }}</div>
          <div class="subTitle">
            兑换藏品需消耗<span style="color: #333; font-weight: 600"
              >{{ data.haveQ || 0 }}个</span
            >{{ data.keyList.name }}
          </div>
        </div>
        <!-- <div class="right">
          <img src="../../../../static/image/key_bg.png" alt="" />
        </div> -->
      </div>
      <div v-if="data.is_real">
        <div class="line"></div>
        <div class="address">
          <div class="left">
            <!-- <span class="noSelect">选择收货地址</span> -->
            <span class="select">商品运费</span>
          </div>
          <div class="right">
            <span>¥{{ data.fare || 0 }}</span>
          </div>
        </div>
      </div>
      <div v-if="data.is_real">
        <div class="line"></div>
        <div class="address" @click="showAddrList">
          <div class="left">
            <!-- <span class="noSelect">选择收货地址</span> -->
            <span class="select">{{ addressInfo }}</span>
          </div>
          <div class="right">
            <span v-if="address_id">重新选择</span>
            <i></i>
          </div>
        </div>
        <div class="line"></div>
      </div>

      <div class="describe" v-html="data.goods.describe"></div>
      <div class="btn">
        <div v-if="data.is_allow == 1">您已达兑换上限</div>
        <div v-else-if="data.is_allow == 2">来晚了一步，已无商品</div>
        <div v-else-if="data.is_open == 1">未到兑换时间</div>
        <div v-else-if="data.is_open == 2">已结束</div>
        <div v-else-if="isSelect">请选择所需藏品材料</div>
        <div v-else-if="!isOk">所选的材料不足，无法兑换</div>
        <div v-else class="ok" @click="exchange">立即兑换</div>
      </div>
    </div>

    <div class="tips" v-if="isShowReminder">
      <div class="box">
        <div class="title">温馨提示</div>
        <div class="info">
          此操作可能会销毁您拥有的相应藏品材料，请谨慎操作！
        </div>
        <div class="btn">
          <div class="left" @click="isShowReminder = false">取消</div>
          <div class="right" @click="userRunCompose">确定</div>
        </div>
      </div>
    </div>
    <div class="tips" v-if="isShowTip">
      <!-- <div class="box" v-if="false"> -->
      <div class="box" v-if="data.is_real">
        <div class="title">兑换成功</div>
        <div>
          <div class="info">兑换成功，请支付运费</div>
          <div class="info1">可在兑换记录-兑换详情中支付运费</div>
        </div>
        <div class="btn">
          <div
            class="center"
            @click="
              showFare = true;
              isShowTip = false;
            "
          >
            去支付
          </div>
        </div>
      </div>
      <div class="box" v-else>
        <div class="title">兑换成功</div>
        <div class="info">兑换成功</div>
        <div class="btn">
          <div class="center" @click="close">确定</div>
        </div>
      </div>
    </div>
    <div class="tips" v-if="warning">
      <div class="box">
        <div class="title">兑换失败</div>
        <div class="info">您的可用交子不足,当前可用{{ pointOwn }}</div>
        <div class="btn">
          <div class="center" @click="warning = false">确定</div>
        </div>
      </div>
    </div>

    <div class="tips" v-if="delCofShow">
      <div class="box box1">
        <div class="title">是否确认删除?</div>
        <div class="btn">
          <div class="left" @click="delCofShow = false">取消</div>
          <div class="right" @click="del">确定</div>
        </div>
      </div>
    </div>

    <div
      v-if="isToHome && !$route.query.platform"
      class="openInApp"
      @click="openApp"
    >
      <div class="img">
        <img src="../../../static/image/logo1.png" alt="" />
      </div>
      <span>APP内打开</span>
    </div>

    <van-popup
      v-model:show="selectAddress"
      position="bottom"
      :style="{ height: '400px' }"
      class="selectAddress"
    >
      <div class="header">
        <div class="title">选择收货地址</div>
        <div class="close" @click="selectAddress = false">
          <van-icon name="cross" size="20px" color="#999" />
        </div>
      </div>
      <div class="main">
        <div class="def" v-if="!addressList.length">
          <img src="../../../static/image/emptyBox.png" alt="" />
          <p>暂无数据</p>
        </div>
        <div class="list">
          <div v-for="item in addressList" :key="item.id" class="listBox">
            <!-- <wMoveJudge class="move" margin="70"> -->
            <div class="listItem" @click="selectAddressId = item.id">
              <div
                class="left"
                :class="selectAddressId === item.id ? 'active' : null"
              ></div>
              <div class="middle">
                <div class="title">
                  {{
                    `${item.province}${item.city}${item.county}${item.detailed}`
                  }}
                </div>
                <div class="subTitle">{{ item.user_name }} {{ item.tel }}</div>
              </div>
              <div class="right">
                <div @click.stop="edit(item)">
                  <img src="../../assets/images/bianji.png" alt="" />
                </div>
                <div @click.stop="delCof(item)">
                  <img src="../../assets/images/del1.png" alt="" />
                </div>
              </div>
            </div>
            <!-- </wMoveJudge> -->
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="btn" @click="selectAddr">确定</div>
        <div class="info" @click="addAddress = true">
          添加新地址<van-icon name="arrow" size="10px" color="#0754D3" />
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model:show="addAddress"
      position="bottom"
      class="selectAddress"
    >
      <div class="header">
        <div class="title">{{ addressQuery.id ? "编辑" : "新增" }}收货地址</div>
        <div class="close" @click="addAddress = false">
          <van-icon name="cross" size="20px" color="#999" />
        </div>
      </div>
      <div class="main">
        <div class="group">
          <div class="left">收货人</div>
          <div class="right">
            <input
              type="text"
              v-model="addressQuery.user_name"
              placeholder="请填写收货人姓名"
            />
          </div>
        </div>
        <div class="group">
          <div class="left">电话</div>
          <div class="right">
            <input
              type="text"
              v-model="addressQuery.tel"
              placeholder="请填写收货手机号码"
            />
          </div>
        </div>
        <div class="group">
          <div class="left">地区</div>
          <div class="right" @click="show = true">
            {{ fieldValue }}
          </div>
        </div>
        <div class="group">
          <div class="left">详细地址</div>
          <div class="right">
            <input
              type="text"
              v-model="addressQuery.detailed"
              placeholder="如街道、门牌号、小区等"
            />
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="btn" @click="addAddressSubmit">确认</div>
        <div class="info" @click="addAddress = false">
          选择已有地址<van-icon name="arrow" size="10px" color="#0754D3" />
        </div>
      </div>
    </van-popup>

    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        :options="options"
        @close="show = false"
        @finish="onFinish"
      />
    </van-popup>

    <van-popup v-model="showFare" round position="bottom">
      <div class="pay">
        <div class="top">
          支付运费
          <i class="close" @click="showFare = false"></i>
        </div>
        <div class="middle">
          <div class="pic">
            <div class="picItem">¥{{ data.fare }}</div>
            <div class="info">支付运费后商品才可发货</div>
          </div>
          <div class="payType">
            <div class="payTitle">支付方式</div>
            <div class="listItem">
              <div class="left">
                华夏钱包 <span>（剩余¥{{ anyUserinfo.money }}）</span>
              </div>
              <div class="right">
                <van-checkbox v-model="checked"></van-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom" @click="goPay">支付</div>
      </div>
    </van-popup>

    <van-overlay :show="showPwd">
      <div class="wap">
        <div class="wap_affirm">
          <div
            @click="
              showPwd = false;
              value = '';
            "
            class="cha"
          >
            ×
          </div>
          <div>请输入交易密码</div>
          <div class="forgotPwd"></div>
          <!-- <div class="forgotPwd" @click="$router.push('/forgotPwd')">
            忘记密码
          </div> -->
        </div>
        <div class="wap_form">订单金额</div>
        <div class="wap_num">￥{{ data.fare }}</div>
        <div class="wap_balance">
          余额<span
            >(￥
            {{ anyUserinfo.money }}
            )</span
          >
        </div>
        <!-- 密码输入框 -->
        <van-password-input
          :value="value"
          :error-info="errorInfo"
          :gutter="10"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
        />
        <!-- 数字键盘 -->
        <van-number-keyboard
          :show="showKeyboard"
          @blur="showKeyboard = true"
          @input="onInput"
          @delete="onDelete"
        />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { addBridgeForAndroidWebView } from "sdbridge-android";
import { wMoveJudge } from "wade-ui";
import { city } from "../../api/config";
export default {
  name: "jiaoziChangeCenter",
  data() {
    return {
      addressQuery: {
        user_name: "",
        tel: "",
        province: "",
        city: "",
        county: "",
        detailed: "",
      },
      value: "",
      delCofShow: false,
      showKeyboard: true,
      showPwd: false,
      showFare: false,
      initLoading: true,
      data: { goods: {}, haveGoodList: [] },
      isShowReminder: false,
      isShowTip: false,
      token: null,
      order_no: null,
      warning: false,
      pointOwn: 0,
      isAndroid:
        navigator.userAgent.indexOf("Android") > -1 ||
        navigator.userAgent.indexOf("Adr") > -1,
      isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      bridge: null,
      isToHome: false,
      selectAddress: false,
      addAddress: false,
      fieldValue: "请选择省市区",
      show: false,
      cascaderValue: "",
      errorInfo: "",
      options: city,
      addressList: [],
      selectAddressId: null,
      address_id: null,
      moveJudgeLeft: true,
      delItem: null,
      anyUserinfo: {},
      checked: true,
      id: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path === "/") {
        vm.isToHome = true;
      } else {
        vm.isToHome = false;
      }
    });
  },
  computed: {
    addressInfo() {
      if (this.address_id) {
        const info = this.addressList.find((e) => e.id === this.address_id);
        const text = `${info.province}${info.city}${info.county}${info.detailed}`;
        return `配送至: ${text}`;
      } else {
        return "选择收货地址";
      }
    },
    isOk() {
      let ok = true;
      this.data.haveGoodList.forEach((e) => {
        if ((this.total(e.id) || 0) < e.number) {
          ok = false;
        }
      });
      return ok;
    },
    isSelect() {
      if (Object.keys(this.$store.state.needGoods).length > 0) {
        let isSel = true;
        Object.keys(this.$store.state.needGoods).forEach((e) => {
          if (this.$store.state.needGoods[e].total > 0) {
            isSel = false;
          }
        });
        return isSel;
      }
      return true;
    },
  },
  mounted() {
    this.initLoading = true;
    this.token = this.$route.query.token;
    this.getGoodsComposeById();
    let bridge = window.WebViewJavascriptBridge;
    if (!bridge) {
      console.log("TypeScript正在尝试挂载");
      addBridgeForAndroidWebView();
      //@ts-ignore
      bridge = window.WebViewJavascriptBridge;
      if (bridge) {
        console.log(
          "window.WebViewJavascriptBridge, 已经被TypeScript挂载成功!"
        );
        this.bridge = bridge;
      }
    } else {
      this.bridge = bridge;
      console.log("window.WebViewJavascriptBridge 挂载成功！！");
    }
    //余额信息
    let params = {
      token: this.token,
    };
    this.$api.anyUserinfo(params).then((res) => {
      if (res.code == 0) {
        this.anyUserinfo = res.data;
      }
    });
  },
  watch: {
    addAddress(val, oldVal) {
      if (!oldVal) {
        this.fieldValue = "请选择省市区";
        this.cascaderValue = "";
        this.addressQuery = {
          user_name: "",
          tel: "",
          province: "",
          city: "",
          county: "",
          detailed: "",
        };
      }
    },
  },
  methods: {
    selectAddr() {
      if (!this.selectAddressId) return this.$toast("请选择地址");
      this.address_id = this.selectAddressId;
      this.selectAddress = false;
    },
    // 获取地址列表
    getAdressList() {
      if (!this.token) {
        if (this.$route.query.platform) {
          if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            control.jumptoapp(JSON.stringify({ key: -1 }));
          } else {
            this.bridge.callHandler(
              "jumptoapp",
              { key: -1 },
              function (response) {
                console.log(JSON.stringify(response));
              }
            );
          }
        } else {
          this.$router.push("/login");
        }
        return;
      }
      this.$api.adressList({ token: this.token }).then((e) => {
        if (e.code === 0) {
          this.addressList = e.data;
        }
      });
    },
    // 添加地址
    addAddressSubmit() {
      if (!this.addressQuery.user_name) return this.$toast("请输入收货人");
      if (!this.addressQuery.tel) return this.$toast("请输入手机号");
      if (!/^[1][0-9]{10}$/.test(this.addressQuery.tel))
        return this.$toast("手机号格式不正确");
      if (!this.addressQuery.province) return this.$toast("请选择省份");
      if (!this.addressQuery.city) return this.$toast("请选择市");
      if (!this.addressQuery.county) return this.$toast("请选择区");
      if (!this.addressQuery.detailed) return this.$toast("请输入详细地址");

      const query = {
        ...this.addressQuery,
        token: this.token,
      };
      this.$api.addAdress(query).then((e) => {
        if (e.code === 0) {
          this.$toast("添加成功");
          this.getAdressList();
          this.addAddress = false;
        } else {
          this.$toast(e.msg);
        }
      });
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.addressQuery.province = selectedOptions[0].text;
      this.addressQuery.city = selectedOptions[1].text;
      this.addressQuery.county = selectedOptions[2].text;
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
    },
    goBack() {
      if (this.isToHome) {
        this.$router.replace("/home");
      } else {
        this.$router.back();
      }
    },
    openApp() {
      window.open("https://v.hxnft.shop/appDownload/index.html");
    },
    // 分享
    clickRight() {
      if (this.$route.query.platform) {
        const host = process.env.VUE_APP_HOST;
        if (this.isAndroid) {
          // eslint-disable-next-line no-undef
          control.jumptoapp(
            JSON.stringify({
              key: -3,
              logo: this.data.goods.showPic,
              title: this.data.goods.name,
              subTitle: "为你推荐我在华夏数艺发现的好物",
              link: `${host}/#/jiaoziChangeCenter?id=${this.$route.query.id}`,
            })
          );
        } else {
          this.bridge.callHandler(
            "jumptoapp",
            {
              key: -3,
              logo: this.data.goods.showPic,
              title: this.data.goods.name,
              subTitle: "为你推荐我在华夏数艺发现的好物",
              link: `${host}/#/jiaoziChangeCenter?id=${this.$route.query.id}`,
            },
            function (response) {
              console.log(JSON.stringify(response));
            }
          );
        }
      }
    },
    goTo(item) {
      if (this.token) {
        this.$router.push(
          `/jiaoziChangeSelect?id=${item.id}&destory=${item.destory}&number=${
            item.number
          }&checkGoods=${item.checkGoods ? 1 : 0}&token=${this.token}`
        );
      } else {
        if (this.$route.query.platform) {
          if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            control.jumptoapp(JSON.stringify({ key: -1 }));
          } else {
            this.bridge.callHandler(
              "jumptoapp",
              { key: -1 },
              function (response) {
                console.log(JSON.stringify(response));
              }
            );
          }
        } else {
          this.$router.push("/login");
        }
      }
    },
    // 兑换
    exchange() {
      if (this.data.is_real == 1 && !this.address_id)
        return this.$toast("请选择地址");
      if (Number(this.pointOwn) < Number(this.data.pointNumber)) {
        this.warning = true;
        return;
      }
      this.isShowReminder = true;
    },
    total(id) {
      if (this.$store.state.needGoods[id]) {
        return this.$store.state.needGoods[id].total;
      } else {
        return 0;
      }
    },
    // 兑换接口
    async userRunCompose() {
      try {
        const list = [];
        Object.entries(this.$store.state.needGoods).forEach(([v, k]) => {
          list.push({
            id: v,
            childId: k.id.join(","),
          });
        });
        const query = {
          id: this.$route.query.id,
          list: JSON.stringify(list),
          token: this.token,
        };
        if (this.address_id) {
          query.address_id = this.address_id;
        }
        if (this.data.checkGoodsList && this.data.checkGoodsList.id) {
          const id = this.data.checkGoodsList.id;
          const idList = this.$store.state.needGoods[id];
          if (this.$store.state.needGoods && idList) {
            query.checkList = idList.id.join(",");
            const newList = list.filter((e) => e.id != id);
            query.list = JSON.stringify(newList);
          }
        }
        const data = await this.$api.pointRunCompose(query);
        if (data.code === 0) {
          this.isShowTip = true;
          this.id = data.data.logId;
          // this.$router.replace('/mergeRecord');
        } else {
          return this.$toast(data.msg);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isShowReminder = false;
      }
    },
    async getGoodsComposeById() {
      try {
        const query = {
          id: this.$route.query.id,
        };
        if (this.token) {
          query.token = this.token;
        }
        const data = await this.$api.getPointComposeById(query);
        if (data.code === 0) {
          this.pointOwn = data.point_own;
          this.data = data.data || { goods: {}, haveGoodList: [] };
          if (this.data.checkGoodsList && this.data.checkGoodsList.id) {
            this.data.checkGoodsList.checkGoods = true;
            this.data.haveGoodList.push(this.data.checkGoodsList);
          }
        } else {
          return this.$toast(data.msg);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.initLoading = false;
      }
    },
    close() {
      this.isShowTip = false;
      this.selectAddressId = null;
      this.address_id = null;
      // window.location.reload();
      this.$store.commit("SET_NEEDGOOD", {});
    },
    edit(item) {
      this.addAddress = true;
      this.$nextTick(() => {
        this.addressQuery = item;
        this.fieldValue = `${item.province}/${item.city}/${item.county}`;
      });
    },
    delCof(item) {
      this.delCofShow = true;
      this.delItem = item;
    },
    del() {
      const item = this.delItem;
      this.$api.delAdress({ token: this.token, id: item.id }).then((e) => {
        if (e.code === 0) {
          if (this.selectAddressId === item.id || this.address_id === item.id) {
            this.selectAddressId = null;
            this.address_id = null;
          }
          this.getAdressList();
          this.delCofShow = false;
          this.delItem = null;
        } else {
          this.$toast(e.msg);
        }
      });
    },
    showAddrList() {
      this.selectAddress = true;
      // 获取地址列表
      this.getAdressList();
    },
    goPay() {
      const query = {
        token: this.token,
        id: this.id,
      };
      this.$api.payPostage(query).then((e) => {
        if (e.code === 0) {
          //
          this.order_no = e.data.order_no;
          this.showPwd = true;
          this.showFare = false;
        } else {
          return this.$toast(e.msg);
        }
      });
    },
    onDelete() {
      this.value = this.value.slice(0, this.value.length - 1);
    },
    // 余额支付
    onInput(key) {
      if (this.value.length > 5) return;
      this.value = (this.value + key).slice(0, 6);
      if (this.value.length === 6) {
        this.runPayComposerPostage();
      }
    },
    runPayComposerPostage() {
      const query = {
        token: this.token,
        order_no: this.order_no,
        pay_channel: "balance",
        payPassword: this.value,
      };
      this.$api.runPayComposerPostage(query).then((e) => {
        if (e.code === 100000) {
          this.showPwd = false;
          this.close();
        }
        this.value = "";
        return this.$toast(e.msg);
      });
    },
  },
};
</script>

<style>
.describe img {
  max-width: 100%;
}
</style>
<style lang="less" scoped>
.describe {
  margin-top: 15px;
}
.address {
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    .noSelect {
      color: #ffaf09;
    }
    .select {
      color: #000000;
    }
  }
  .right {
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    i {
      display: block;
      width: 8px;
      height: 8px;
      border-right: 1px solid #999;
      border-top: 1px solid #999;
      transform: rotate(45deg);
    }
  }
}
.fenxiang {
  width: 20px;
}
.head {
  position: relative;
  .tag {
    // background: rgba(125, 0, 0, 0.6);
    // border-radius: 18px;
    padding: 10px 20px 0;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #f8e9d5;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 40px;
    display: flex;
    align-items: center;
    background: linear-gradient(
      0deg,
      rgba(75, 42, 19, 0.82) 18%,
      rgba(75, 42, 19, 0) 100%
    );
    img {
      height: 23px;
      width: auto;
      margin-right: 10px;
      vertical-align: middle;
    }
  }
  img {
    width: 100%;
    vertical-align: middle;
  }
}
.tips {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2015;
  .box {
    padding: 20px;
    box-sizing: border-box;
    width: 282px;
    height: 170px;
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.box1 {
      height: 140px;
    }
    .title {
      font-size: 15px;
      text-align: center;
      font-weight: 600;
      color: #000;
    }
    .info {
      font-size: 14px;
      color: #666;
      text-align: center;
    }
    .info1 {
      text-align: center;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #548ae4;
      margin-top: 5px;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        width: 80px;
        height: 28px;
        background: #0754d3;
        border-radius: 3px;
        border: 1px solid #0754d3;
        color: #fff;
        &.left {
          color: #0754d3;
          border: 1px solid #0754d3;
          background: transparent;
        }
        &.center {
          margin: 0 auto;
        }
      }
    }
  }
}
.content {
  padding: 10px 20px 60px;
  .title {
    font-size: 14px;
    font-weight: 600;
  }
  .pic {
    display: flex;
    align-items: flex-end;
    margin-top: 15px;
    .left {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ff9228;
      line-height: 19px;
      span {
        font-size: 12px;
      }
    }
    .middle {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      text-decoration: line-through;
      margin: 0 10px 0 5px;
    }
    .right {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ff9228;
      border: 1px solid #ff9228;
      border-radius: 2px;
      line-height: 12px;
      padding: 3px 5px;
    }
  }
  .btn {
    position: fixed;
    padding: 10px 20px;
    background: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    div {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 8px 0;
      border-radius: 3px;
      background: #ebebeb;
      color: #999999;
      &.ok {
        background: #0754d3;
        color: #fff;
      }
    }
  }
  .tag {
    display: flex;
    font-size: 12px;
    margin: 10px 0;
    .left {
      background: #abaab9;
      color: #fff;
      padding: 2px 5px;
    }
    .right {
      padding: 2px 5px;
      color: #333;
      background: #f3f4f8;
    }
  }
  .time {
    font-size: 12px;
    color: #666;
    margin-bottom: 20px;
  }
  .line {
    margin: 0 -20px;
    height: 10px;
    background: #f8f8f8;
  }
  .science {
    font-size: 14px;
    font-weight: 600;
    margin: 20px 0 5px;
  }
  .info1 {
    font-size: 12px;
    color: #f93838;
  }
  .material {
    display: flex;
    height: 110px;
    align-items: flex-end;
    overflow-x: auto;
    .item {
      width: 80px;
      height: 80px;
      display: inline-block;
      margin-right: 10px;
      box-shadow: 0px 4px 5px 1px rgb(0 42 59 / 11%);
      &:last-child {
        margin: 0;
      }
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
        overflow: hidden;
      }
      .mask {
        border-radius: 5px;
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.3);
      }
      .ico {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: red;
        right: -8px;
        top: -8px;
        position: absolute;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background: #b5b5b5;
        &.ok {
          background: #ffaf09;
          display: block;
          &::after {
            display: block;
            content: "";
            width: 10px;
            height: 5px;
            border-bottom: 2px solid #fff;
            border-left: 2px solid #fff;
            transform: translate(-50%, -50%) rotate(-45deg);
            visibility: visible;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -2px;
          }
        }
      }
    }
  }
  .info {
    font-size: 12px;
    text-align: center;
    color: #999999;
    margin: 20px 0 15px;
    span {
      color: #f93838;
    }
  }
  .brand {
    height: 54px;
    background: #ffffff;
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 4%);
    border-radius: 5px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    .left {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .right {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .key {
    background: #f8f8f8;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 20px 0;
    .left {
      padding: 15px 20px;
      box-sizing: border-box;
      img {
        width: 60px;
        vertical-align: middle;
      }
    }
    .middle {
      flex: 1;
      .title {
        font-size: 16px;
        font-weight: 600;
        color: #000;
        margin-bottom: 5px;
      }
      .subTitle {
        font-size: 12px;
        color: #999;
      }
    }
    .right {
      img {
        vertical-align: middle;
        width: 70px;
      }
    }
  }
}
.openInApp {
  position: fixed;
  right: 0;
  bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 3px;
  .img {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
  }
  span {
    font-size: 12px;
  }
}
.selectAddress {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .header {
    position: relative;
    width: 100%;
    padding: 10px 0;
    .title {
      text-align: center;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    .close {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .main {
    flex: 1;
    min-height: 1px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 25px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 0 20px;
    .list {
      .listBox {
        overflow: hidden;
        .move {
          display: flex;
          position: relative;
        }
        .listItem {
          background: #fff;
          flex: 1;
          display: flex;
          align-items: center;
          padding: 15px 0;
          border-bottom: 1px solid #f8f8f8;
          .left {
            width: 15px;
            height: 15px;
            border: 1px solid #999999;
            border-radius: 50%;
            &.active {
              border: 1px solid #ffaf09;
              position: relative;
              &::before {
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: #ffaf09;
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
          .middle {
            flex: 1;
            margin: 0 10px;
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 200px;
            .title {
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #000000;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .subTitle {
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .right {
            display: flex;
            align-items: center;
            div {
              margin-left: 20px;
              img {
                width: 15px;
              }
            }
          }
        }
        .del {
          position: absolute;
          right: -60px;
          top: 0;
          height: 100%;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(181deg, #d83a1f 0%, #ea5d32 99%);
          width: 60px;
          margin-right: -10px;
        }
      }
    }
    .group {
      display: flex;
      align-items: center;
      padding: 15px 0;
      border-bottom: 1px solid #f8f8f8;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      .left {
        width: 70px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
      }
      .right {
        flex: 1;
        display: flex;
        align-items: center;
      }
      input {
        width: 100%;
        border: none;
        outline: none;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .bottom {
    padding: 0 25px 5px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    .btn {
      width: 100%;
      height: 40px;
      background: #0754d3;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    .info {
      text-align: center;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #0754d3;
      margin: 10px 0;
    }
  }
}
.def {
  text-align: center;
  margin: 10px auto 0;
  width: 100%;
  img {
    width: 30%;
    margin-right: -20px;
  }
  p {
    font-size: 14px;
    color: #aaa;
  }
}
.wap {
  padding-top: 10px;
  width: 100%;
  background-color: #ffffff;
  // height: 70%;
  position: absolute;
  bottom: 0;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  z-index: 999;
  .wap_affirm {
    font-size: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    div {
      flex: 2;
      text-align: center;
      font-size: 16px;
    }
    .cha {
      flex: 1;
      color: #666666;
      text-align: left;
      font-size: 30px;
    }
    .forgotPwd {
      flex: 1;
      text-align: right;
      font-size: 14px;
      color: rgb(rgb(7 84 211));
    }
  }
  .wap_balance {
    font-size: 10px;
    margin-bottom: 30px;
    span {
      color: #999999;
    }
  }
  .wap_form {
    font-size: 10px;
    margin-top: 20px;
  }
  .wap_num {
    height: 19px;
    font-size: 18px;
    font-weight: 400;
    color: #e76810;
    line-height: 20px;
    margin-top: 10px;
  }
}
/deep/.van-password-input__security li {
  border: 2px #666666 solid;
  border-radius: 5px;
}
/deep/.van-password-input {
  position: relative;
  margin-bottom: 20px;
}
/deep/.van-number-keyboard {
  position: relative !important;
}
.pay {
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px;
  .top {
    font-size: 19px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    padding: 15px 0;
    border-bottom: 1px solid #f8f8f8;
    margin-bottom: 15px;
    position: relative;
    .close {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      width: 15px;
      height: 15px;
      &::after {
        width: 100%;
        height: 1px;
        background: #999999;
        display: block;
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
        content: "";
        height: 100%;
        width: 1px;
        background: #999999;
      }
    }
  }
  .bottom {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    background: #0754d3;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .middle {
    margin-bottom: 25px;
    .pic {
      text-align: center;
      margin-bottom: 30px;
      .picItem {
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #e76810;
      }
      .info {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
    .payType {
      .payTitle {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        margin-bottom: 20px;
      }
      .listItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          span {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
        .right {
        }
      }
    }
  }
}
</style>
